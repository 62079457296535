.apply-buttons-container,
.sorting-buttons-container,
.playlists-container {
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.playlist-button {
  margin: 6px 4px;
  padding: 10px 15px;
  font-size: 16px;
  transition: 0.3s;
}

.playlist-button:hover {
  transform: scale(1.05);
}

.playlist-details-container h1.title {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.sorting-buttons-container {
  max-width: 500px;
}
.sorting-buttons-container .subgroup-buttons-container {
  background-color: #16161d;
  margin: 2px;
  padding: 3px 1px;
  border-radius: 5px;
}

.sorting-buttons-container .subgroup-buttons-container .sorting-button {
  padding: 5px;
  margin: 0 2px;
  border-radius: 5px;
  background-color: #16161d;
  border: 1px solid #2c2c3a;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.8rem;
  transition: 0.2s;
}

.sorting-buttons-container .subgroup-buttons-container .sorting-button:hover {
  background-color: #2c2c3a;
  color: #e0e0e0;
}

.apply-changes-button {
  color: #ff0000;
  transition: 0.3s;
}

.apply-changes-button:hover {
  background: linear-gradient(135deg, #ff0000, #fb3d3d);
  color: #ffffff;
}

Line.chart-js {
  margin: auto;
  height: 30%;
  width: 90%;
}

.chart-features-ruler {
  width: 60%;
  margin: 20px auto;
  border-top: 1px solid #666;
}

.features-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  width: 80%;
  margin: auto;
}

.feature-data {
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 2px;
}

.feature-data.hidden {
  color: gray !important;
}

.feature-data.popularity {
  color: rgb(255, 255, 255);
}

.feature-data.popularity:hover {
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.feature-data.popularity.hidden:hover {
  background-color: gray !important;
  color: rgb(255, 255, 255) !important;
}

.feature-data.tempo {
  color: rgb(75, 192, 192);
}

.feature-data.tempo:hover {
  background-color: rgb(75, 192, 192);
  color: #ffffff;
}

.feature-data.tempo.hidden:hover {
  background-color: gray !important;
  color: rgb(75, 192, 192) !important;
}

.feature-data.dance {
  color: rgb(255, 99, 132);
}

.feature-data.dance:hover {
  background-color: rgb(255, 99, 132);
  color: #ffffff;
}

.feature-data.dance.hidden:hover {
  background-color: gray !important;
  color: rgb(255, 99, 132) !important;
}

.feature-data.energy {
  color: rgb(54, 162, 235);
}

.feature-data.energy:hover {
  background-color: rgb(54, 162, 235);
  color: #ffffff;
}

.feature-data.energy.hidden:hover {
  background-color: gray !important;
  color: rgb(54, 162, 235) !important;
}

.feature-data.happy {
  color: rgb(255, 206, 86);
}

.feature-data.happy:hover {
  background-color: rgb(255, 206, 86);
  color: #ffffff;
}

.feature-data.happy.hidden:hover {
  background-color: gray !important;
  color: rgb(255, 206, 86) !important;
}