*::selection {
  background-color: #1DB954;
  color: #ffffff;
}

.container {
  margin: auto;
  padding: 10px;
  max-width: 900px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.margin-0 {
  margin: 0;
}

.mb-10 {
  margin-bottom: 5%;
}

.flex {
  display: flex;
}

.flex.space-between {
  justify-content: space-between;
}
.flex.items-center {
  align-items: center;
}

.styled-button {
  padding: 5px;
  border-radius: 5px;
  background-color: #16161d;
  border: 1px solid #2c2c3a;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.8rem;
  transition: 0.2s;
}

.styled-button:hover {
  background-color: #2c2c3a;
  color: #e0e0e0;
}

.logout-button {
  padding: 5px 10px;
}