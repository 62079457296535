.app-logo {
  height: 10%;
  pointer-events: none;
  border-radius: 20%;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 10s linear;
  }
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.login-button {
  padding: 10px 20px;
  border-radius: 5px;
  background: linear-gradient(45deg, #1DB954, #0bbd4a);
  color: #16161d;
  font-size: 2rem;
  border: 0px solid #000;
  transition: 0.8s;
  cursor: pointer;
}

.login-button:hover {
  filter: brightness(1.2);
  transform: scale(1.05);
  border: 0px solid #000;
  box-shadow: 0 0 15px #1DB954;
}